
$( document ).ready(function(){

    $('.leadership_cont').on('click', '.leadership_card', function (event) {
        event.preventDefault();
        let leader_id=$(this).attr('id');
        toggle_leader(leader_id);
    });
});

function toggle_leader(leader_id) {
    $('#' + leader_id).find(".leader_bio_cont").toggle();
}